import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiError } from "store/handleResponseError";

import {
  createLeaveApplication,
  deleteLeaveApplication,
  fetchLeaveApplications,
  updateLeaveApplication,
} from "./leaveApplicationAPI";
import { RootState } from "../../store";
import { rosterApi } from "../rosteredShift";

const adapter = createEntityAdapter<LeaveApplication>();

const initialState = adapter.getInitialState();

const leaveApplicationSlice = createSlice({
  name: "leaveApplications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeaveApplications.fulfilled, (state, action) => {
      // setAll to replace all existing entities for switching between "My Leave" and "Leave Management"
      adapter.setAll(state, action.payload.leave_applications);
    });
    builder.addCase(createLeaveApplication.fulfilled, (state, action) => {
      adapter.addOne(state, action.payload.leave_application);
    });
    builder.addCase(updateLeaveApplication.fulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload.leave_application);
    });
    builder.addCase(deleteLeaveApplication.fulfilled, (state, action) => {
      // Updates LeaveApplication.archived to true
      adapter.upsertOne(state, action.payload);
    });

    builder.addMatcher(rosterApi.endpoints.fetchAll.matchFulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.leave_applications);
    });
  },
});

export { leaveApplicationSlice };

export const { selectById: selectLeaveApplicationById, selectAll: selectAllLeaveApplications } =
  adapter.getSelectors<RootState>((state) => state.leaveApplication);

type Period = {
  start: string;
  end: string;
  units: number;
};
type OverlappingLeavePeriods = {
  periods: Period[];
};

// TODO(store): is this pattern generally useful everywhere for typing the `error` field?
const myBaseQuery: BaseQueryFn<string | FetchArgs, unknown, ApiError> = fetchBaseQuery() as any;

const leaveApplicationApi = createApi({
  reducerPath: "leaveApplicationApi",
  baseQuery: myBaseQuery,

  endpoints: (builder) => ({
    overlappingLeaveApplications: builder.query<
      OverlappingLeavePeriods,
      {
        start: string;
        end: string;
      }
    >({
      query: (params) => ({
        url: `/api/overlapping_leave_periods`,
        method: "GET",
        params,
      }),
    }),

    uploadFile: builder.mutation<{ filename: string }, FormData>({
      query: (body) => ({
        url: `/api/upload_file`,
        method: "POST",
        body,
      }),
    }),
    upload: builder.mutation<
      Upload,
      {
        filename: string;
        original_filename: string;
        employee_id: number;
      }
    >({
      query: (body) => ({
        url: `/api/upload`,
        method: "POST",
        body,
      }),
    }),

    conflictLeaveApplications: builder.query<
      { leave_applications?: LeaveApplication[] },
      {
        start: string;
        end: string;
      }
    >({
      query: (params) => ({
        url: `/api/leave_conflict`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export { leaveApplicationApi };
export const {
  useOverlappingLeaveApplicationsQuery,
  useUploadFileMutation,
  useUploadMutation,
  useConflictLeaveApplicationsQuery,
} = leaveApplicationApi;
