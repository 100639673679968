import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import EmployeeName from "components/EmployeeName";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllLeaveApplications } from "store/reducers";
import { deleteLeaveApplication, updateLeaveApplication } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager } from "store/reducers/config/configSlice";

import LeaveApplicationModal from "./leave_application_modal";
import LeaveDeclineModal from "./leave_decline_modal";
import LeavePeriod from "./LeavePeriod";
import { Button } from "../components/button";
import ConfirmationModal from "../shared/confirmation_modal";

export function canEditLeaveApplication({
  leaveApplication,
  currentEmployee,
}: {
  leaveApplication: LeaveApplication;
  currentEmployee?: Employee;
}) {
  return (
    !leaveApplication.archived &&
    !leaveApplication.approved &&
    currentEmployee &&
    leaveApplication.xero_LeaveApplicationID == null
  );
}

export function canUnapproveLeaveApplication({
  isManager,
  leaveApplication,
}: {
  isManager: boolean;
  leaveApplication: LeaveApplication;
}) {
  return isManager && leaveApplication.approved;
}

export function canApproveLeaveApplication({
  isManager,
  leaveApplication,
}: {
  isManager: boolean;
  leaveApplication: LeaveApplication;
}) {
  return isManager && !leaveApplication.approved && !leaveApplication.archived;
}

type Props = {
  employee?: Employee;
};

/**
 * employee is optional when used in LeaveManagement
 */
export function LeaveRequests(props: Props) {
  const { employee } = props;

  const { data: configData } = useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);

  const dispatch = useAppDispatch();

  const leaveApplications = useAppSelector(selectAllLeaveApplications);

  const [showAll, setShowAll] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState<any>();
  const [leaveApplicationModal, setLeaveApplicationModal] = React.useState<any>();

  const filteredLeaveApplications = showAll ? leaveApplications : leaveApplications.slice(0, 3);

  function onClickDelete(leave: LeaveApplication, reason?: string) {
    dispatch(
      deleteLeaveApplication({
        leaveApplicationId: leave.id,
        reason: reason,
      }),
    );
    closeConfirmationModal();
  }

  function archiveLeave(leave: LeaveApplication) {
    // If user is isManager => give reason to decline
    if (isManager) {
      openLeaveDeclineModal(leave);
      return;
    }
    openConfirmationModal(leave);
  }

  function updateLeave(id: number, data: Partial<LeaveApplication>) {
    dispatch(
      updateLeaveApplication({
        leaveApplicationId: id,
        body: data,
      }),
    );
  }

  function openLeaveDeclineModal(leave: LeaveApplication) {
    setConfirmationModal(
      <LeaveDeclineModal
        closeLeaveDeclineModal={() => setConfirmationModal(undefined)}
        onClickArchive={(reason: string) => onClickDelete(leave, reason)}
      />,
    );
  }

  function openConfirmationModal(leave: LeaveApplication) {
    setConfirmationModal(
      <ConfirmationModal
        closeConfirmationModal={() => setConfirmationModal(undefined)}
        onClickYes={() => onClickDelete(leave)}
      />,
    );
  }

  function closeConfirmationModal() {
    setConfirmationModal(undefined);
  }

  function openLeaveApplicationModal(leave: LeaveApplication) {
    setLeaveApplicationModal(
      <LeaveApplicationModal closeLeaveApplicationModal={closeLeaveApplicationModal} leave={leave} />,
    );
  }

  function closeLeaveApplicationModal() {
    setLeaveApplicationModal(undefined);
  }

  function toggleShowAll() {
    setShowAll(!showAll);
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th rowSpan={1} colSpan={7} className="table-header-name">
              <FontAwesomeIcon icon="plane-departure" /> Leave Requests
              {employee && <span className="whitespace-nowrap"> ({employee.name})</span>}
            </th>
          </tr>
          <tr>
            <th className="table-header-column-name w-1/12">Employee</th>
            <th className="table-header-column-name w-2/12">Date</th>
            <th className="table-header-column-name w-1/12">Units</th>
            <th className="table-header-column-name w-1/12">Type</th>
            <th className="table-header-column-name w-3/12">Notes</th>
            <th className="table-header-column-name w-1/12">Requested On</th>
            <th className="table-header-column-name w-3/12" />
          </tr>
        </thead>
        <tbody>
          {filteredLeaveApplications.map((leave, i1) => {
            const archiveButtonTitle =
              leave.xero_LeaveApplicationID !== null
                ? "If you published by mistake, open Xero and reject it there."
                : undefined;
            return (
              <tr key={i1} className={classNames({ "line-through": leave.archived })}>
                <td className="table-body-cell">
                  <Link
                    to={{
                      pathname: "/leave",
                      search: `?e=${leave.employee_id}`,
                    }}
                  >
                    <EmployeeName employeeId={leave.employee_id} />
                  </Link>
                </td>
                <td className="table-body-cell">
                  <p>
                    {format(new Date(leave.xero_StartDate), "dd/MM/yyyy")} -{" "}
                    {format(new Date(leave.xero_EndDate), "dd/MM/yyyy")}
                  </p>
                </td>
                <td className="table-body-cell">
                  {leave.leave_period_ids.map((id) => {
                    return (
                      <div key={id}>
                        <LeavePeriod leavePeriodId={id} />
                      </div>
                    );
                  })}
                </td>
                <td className="table-body-cell">
                  <p>{leave.xero_LeaveTypeName}</p>
                </td>
                <td className="table-body-cell">
                  <p>{leave.xero_Title}</p>
                  {leave.attachment && (
                    <a href={"/api/upload/" + leave.attachment.filename} target="_blank" rel="noreferrer">
                      <Button title={leave.attachment.original_filename} size="sm">
                        <FontAwesomeIcon icon="paperclip" />
                        Attachment
                      </Button>
                    </a>
                  )}
                </td>
                <td className="table-body-cell">
                  {leave.created && (
                    <p title={format(new Date(leave.created), "h:mm a")}>
                      {format(new Date(leave.created), "dd/MM/yyyy")}
                    </p>
                  )}
                </td>
                <td className="table-body-cell">
                  {/*Show archive button when leave hasn't been archived, approved or published yet*/}
                  {!leave.archived && !leave.approved && (
                    <Button colour="danger" onClick={() => archiveLeave(leave)} title={archiveButtonTitle}>
                      Decline
                    </Button>
                  )}

                  {leave.archived && (
                    <Button colour="danger" disabled={true} title={leave.archived_reason}>
                      Decline <FontAwesomeIcon icon="ban" />
                    </Button>
                  )}

                  {/*If leave has been archived but hasn't been published, show unarchive button to isManager*/}
                  {leave.archived && isManager && (
                    <Button
                      colour="accent"
                      title="Unarchive leave application"
                      onClick={() =>
                        updateLeave(leave.id, {
                          ...leave,
                          archived: false,
                        })
                      }
                    >
                      Undecline <FontAwesomeIcon icon="trash-restore" />
                    </Button>
                  )}

                  {/*Approve button*/}
                  {canApproveLeaveApplication({
                    isManager,
                    leaveApplication: leave,
                  }) && (
                    <Button
                      className="ml-1"
                      colour="accent"
                      onClick={() =>
                        updateLeave(leave.id, {
                          ...leave,
                          approved: true,
                        })
                      }
                      title={`This approves leave application but does not publish to Xero.`}
                    >
                      Approve
                    </Button>
                  )}
                  {/* Approved */}
                  {leave.approved && (
                    <Button colour="success" disabled={true} title="Approved">
                      Approved <FontAwesomeIcon icon="check" />
                    </Button>
                  )}

                  {/*Show unapprove button to managers */}
                  {/* if leave hasn't been published `leave.xero_LeaveApplicationID == null` */}
                  {canUnapproveLeaveApplication({
                    isManager,
                    leaveApplication: leave,
                  }) && (
                    <Button
                      className="ml-1"
                      colour="accent"
                      onClick={() =>
                        updateLeave(leave.id, {
                          ...leave,
                          approved: false,
                        })
                      }
                      title={`This unapproves leave application`}
                    >
                      <>
                        Unapprove <FontAwesomeIcon icon="times" />
                      </>
                    </Button>
                  )}

                  {/*Show edit button when the leave application has not been approved or archived and has not been published yet*/}
                  {canEditLeaveApplication({
                    leaveApplication: leave,
                    currentEmployee: configData?.currentEmployee,
                  }) && (
                    <Button
                      className="ml-1"
                      colour="accent"
                      onClick={() => openLeaveApplicationModal(leave)}
                      title={`Click to edit this leave application.`}
                    >
                      Edit
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        {confirmationModal}
        {leaveApplicationModal}
      </table>
      {leaveApplications.length > 3 && (
        <Button colour="primary" onClick={toggleShowAll}>
          {showAll ? "Show Less" : "Show More"}
        </Button>
      )}
    </div>
  );
}
