import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "store/handleResponseError";
import { RootState } from "store/store";

import { selectAllMessages } from "./messageSlice";

type ReadThreadPayload = {
  employee_id: number;
  currentEmployeeId: number;
};

type _BaseSendMessage = Pick<Message, "body" | "rostered_shift_id" | "type" | "requires_ack">;

type SendMessage = _BaseSendMessage & Pick<Message, "employee_id">;

type SendMessageGroup = { employee_ids: number[]; message: _BaseSendMessage };

const fetchMessages = createAsyncThunk(
  "message/fetchAll",
  async (params: { management: boolean; terminated_employees: boolean }, thunkAPI) => {
    const url = new URL("/api/messages", location.origin);
    url.searchParams.append("management", String(params.management));
    url.searchParams.append("terminated_employees", String(params.terminated_employees));
    const response = await fetch(url, {
      method: "GET",
    });
    return handleResponseError<{ messages: Message[] }>(response, thunkAPI);
  },
);

const readThread = createAsyncThunk(
  "message/read",
  async ({ employee_id, currentEmployeeId }: ReadThreadPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allMessages = selectAllMessages(state);

    const unreadMessages = allMessages.filter(
      (message) =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        message.read_at === null && message.employee_id === employee_id && message.author_id !== currentEmployeeId,
    );

    const results = await Promise.all(
      unreadMessages.map(async (message) => {
        const response = await fetch(`/api/messages/${message.id}/read`, {
          method: "POST",
        });
        return await handleResponseError<Message>(response, thunkAPI);
      }),
    );

    let messageResults: Message[] = results.filter((result): result is Message => "id" in result);

    return messageResults;
  },
);

const sendMessage = createAsyncThunk(
  "message/send",
  async (
    {
      data,
      currentEmployeeId,
    }: {
      data: SendMessage;
      currentEmployeeId: number;
    },
    thunkAPI,
  ) => {
    if (data.employee_id == null && currentEmployeeId) {
      data.employee_id = currentEmployeeId;
    }

    const response = await fetch("/api/messages", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return handleResponseError<Message>(response, thunkAPI);
  },
);

const sendGroupMessage = createAsyncThunk(
  "messages/group",
  async (
    {
      data,
    }: {
      data: SendMessageGroup;
    },
    thunkAPI,
  ) => {
    const response = await fetch("/api/messages/group", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    return handleResponseError<{ messages: Message[] }>(response, thunkAPI);
  },
);

const acknowledgeMessage = createAsyncThunk(
  "message/acknowledge",
  async (
    {
      messageId,
    }: {
      messageId: number;
    },
    thunkAPI,
  ) => {
    const response = await fetch(`/api/messages/${messageId}/acknowledge`, {
      method: "POST",
    });
    return handleResponseError<Message>(response, thunkAPI);
  },
);

export { fetchMessages, readThread, sendMessage, acknowledgeMessage, sendGroupMessage };
