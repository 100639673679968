import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createThreads, groupMessagesByEmployee } from "helpers/messages";

import { acknowledgeMessage, fetchMessages, readThread, sendGroupMessage, sendMessage } from "./messageAPI";
import { RootState } from "../../store";

export type Thread = {
  messages: Message[];
  read: boolean;
  employee_id: number;
};

const messageAdapter = createEntityAdapter<Message>();

const initialState = messageAdapter.getInitialState();

const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.fulfilled, (state, action) => {
        messageAdapter.setAll(state, action.payload.messages);
      })
      .addCase(readThread.fulfilled, (state, action) => {
        messageAdapter.upsertMany(state, action.payload);
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        messageAdapter.upsertOne(state, action.payload);
      })
      .addCase(sendGroupMessage.fulfilled, (state, action) => {
        messageAdapter.upsertMany(state, action.payload.messages);
      })
      .addCase(acknowledgeMessage.fulfilled, (state, action) => {
        messageAdapter.upsertOne(state, action.payload);
      });
  },
});

export const { selectById: selectMessageById, selectAll: selectAllMessages } = messageAdapter.getSelectors<RootState>(
  (state) => state.message,
);

export const selectThreads = createSelector([selectAllMessages], (messages) => {
  const groupedMessages = groupMessagesByEmployee(messages);
  return createThreads(groupedMessages);
});

export { messageAdapter, messageSlice };
